import React, { Component } from 'react';
import { NavLink, Link, withRouter } from "react-router-dom";

import config from './../config';
import PageView from './PageView';

class DownloadList extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/nidurhal/';

		this.state = {
			listData: []
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		// Sæki gögn til APA
		this.setState({
			listData: []
		});

		fetch(this.url+'?link_id='+this.props.match.params.link_id)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					listData: json.files || [],
					error: !json.files
				});
			}.bind(this));
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<h3>Gögn til niðurhals</h3>
					{
						this.state.listData.length > 0 &&
						<ul className="list-group">
							{
								this.state.listData.map(function(item, index) {
									return <li className="list-group-item" key={index}>
										<a href={this.url+'?file='+item.file}>{item.file}</a>
										<span class="float-right badge badge-pill">{item.size}</span>
									</li>;
								}.bind(this))
							}
						</ul>
					}
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(DownloadList);
