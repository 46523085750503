import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Link, NavLink, Switch, withRouter } from "react-router-dom";

import SearchBox from './components/SearchBox';
import EntryView from './components/EntryView';
import SearchResultsList from './components/SearchResultsList';
import PageMenu from './components/PageMenu';
import PageView from './components/PageView';
import MolarList from './components/MolarList';
import MolarView from './components/MolarView';
import SiteFooter from './components/SiteFooter';
import GoogleAnalytics from './components/GoogleAnalytics';
import DownloadPage from './components/DownloadPage';
import DownloadList from './components/DownloadList';
import createBrowserHistory from 'history/createBrowserHistory';
import config from './config';

import _ from 'underscore';

import logo from './images/sam-logo.png';

const sites = {
	IS: 1,
	EN: 2,
	MALTAEKNI: 3,
	BIN_KJARNI: 8,
	MALTAEKNI_EN: 4,
	BIN_KJARNI_EN: 9
}

class App extends Component {
	constructor(props) {
		super(props);

		this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this);
		this.searchResultsListSearchHandler = this.searchResultsListSearchHandler.bind(this);
		this.loadStartHandler = this.loadStartHandler.bind(this);
		this.loadFinishHandler = this.loadFinishHandler.bind(this);

		window.leit_id = this.resultsLinkLeitIdHandler.bind(this);
		window.leit = this.resultsLinkLeitHandler.bind(this);

		this.state = {
			menuOpen: false,
			searchParams: {
				searchString: '',
				beygingarmynd: false
			},
			routePath: '/',
			loading: false
		};
	}

	componentDidMount() {
		// Hér hlustum við eftir breytingu á route
		this.refs.router.history.listen(function(event) {
			this.setState({
				routePath: event.pathname,
				menuOpen: false
			});

			window.scrollTo(0, 0)
		}.bind(this));

		document.addEventListener('mousedown', function(event) {
			if (!this.state.menuOpen) {
				return;
			}

			if (this.refs.menuButton.contains(event.target)) {
				event.stopPropagation();

				return;
			}

			if (this.refs.menuContent && !this.refs.menuContent.contains(event.target)) {
				this.setState({
					menuOpen: false
				})
			}
		}.bind(this));
	}

	componentDidUpdate(prevProps) {
	}

	resultsLinkLeitIdHandler(id) {
		this.refs.router.history.push('/beyging/'+id);

		return false;
	}

	resultsLinkLeitHandler(search, beygingarmynd) {
		this.refs.router.history.push('/leit/'+(beygingarmynd ? 'beygingarmynd/' : '')+search);

		return false;
	}


	menuButtonClickHandler(event) {
		if (!this.state.menuOpen) {
			this.refs.menuContent.scrollTop = 0;
		}

		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	searchResultsListSearchHandler(searchParams) {
		this.setState({
			searchParams: searchParams
		});
	}

	loadStartHandler() {
		this.setState({
			loading: true
		});
	}

	loadFinishHandler() {
		this.setState({
			loading: false
		});
	}

	render() {
		let routePath = this.refs.router && this.refs.router.history.location.pathname != this.state.routePath ? this.refs.router.history.location.pathname : this.state.routePath;

		let isOnContentPage = routePath != '/' && 
			routePath.toLowerCase().substr(0, 9) != '/beyging/' &&
			routePath.toLowerCase().substr(0, 6) != '/leit/';

		let site = 1;
		let siteInitials = 'is';
		let langInitials = 'is';

		if (routePath.toLowerCase().substr(0, '/dmii/'.length) == '/dmii/') {
			site = sites.EN;
			siteInitials = 'en';
			langInitials = 'en';
		}
		if (routePath.toLowerCase().substr(0, '/gogn/'.length) == '/gogn/') {
			site = sites.MALTAEKNI;
			siteInitials = 'maltaekni';
			langInitials = 'is';
		}
		if (routePath.toLowerCase().substr(0, '/DMII/LTdata/'.length) == '/dmii/ltdata/') {
			site = sites.MALTAEKNI_EN;
			siteInitials = 'maltaekni';
			langInitials = 'en';
		}
		if (routePath.toLowerCase().substr(0, '/binkjarni/'.length) == '/binkjarni/') {
			site = sites.BIN_KJARNI;
			siteInitials = 'kjarni';
			langInitials = 'is';
		}
		if (routePath.toLowerCase().substr(0, '/dmii/dmii-core/'.length) == '/dmii/dmii-core/') {
			site = sites.BIN_KJARNI_EN;
			siteInitials = 'kjarni';
			langInitials = 'en';
		}

		if (!this.refs.router) {
			setTimeout(function() {
				this.forceUpdate();
			}.bind(this), 100);
		}

		let appTitleEl;

		if (site == sites.MALTAEKNI) {
			appTitleEl = <div className="app-title-container">
				<h1 className="app-title">
					<Link to="/">
						<span className="sub">Beygingarlýsing íslensks nútímamáls </span>
						<span>BÍN</span>
					</Link>
				</h1>
				<small>Ritstjóri <a href="mailto:Kristín Ingibjörg Hlynsdóttir<kristin.ingibjorg.hlynsdottir@arnastofnun.is>"> Kristín Ingibjörg Hlynsdóttir</a></small>
				<small>Ritstjóri <a href="mailto:Kristín Bjarnadóttir<kristinb@hi.is>">Kristín Bjarnadóttir</a></small>
				<h2>Máltæknigögn</h2>
			</div>;
		}
		else if (site == sites.MALTAEKNI_EN) {
			appTitleEl = <div className="app-title-container">
				<h1 className="app-title">
					<Link to="/DMII/">
						<span className="sub">The Database of Icelandic Morphology </span>
						<span>DIM</span>
					</Link>
				</h1>
				<small>Editor <a href="mailto:Kristín Ingibjörg Hlynsdóttir<kristin.ingibjorg.hlynsdottir@arnastofnun.is>"> Kristín Ingibjörg Hlynsdóttir</a></small>
				<small>Editor <a href="mailto:Kristín Bjarnadóttir<kristinb@hi.is>">Kristín Bjarnadóttir</a></small>
				<h2>LT Data</h2>
			</div>;
		}
		else if (site == sites.BIN_KJARNI) {
			appTitleEl = <div className="app-title-container">
				<h1 class="app-title">
					<Link to="/">
						<span className="sub">Beygingarlýsing íslensks nútímamáls </span>
						<span>BÍN</span>
					</Link>
				</h1>
				<small>Ritstjóri <a href="mailto:Kristín Ingibjörg Hlynsdóttir<kristin.ingibjorg.hlynsdottir@arnastofnun.is>"> Kristín Ingibjörg Hlynsdóttir</a></small>
				<small>Ritstjóri <a href="mailto:Kristín Bjarnadóttir<kristinb@hi.is>">Kristín Bjarnadóttir</a></small>
				<h2>BÍN-kjarninn</h2>
			</div>
		}
		else if (site == sites.BIN_KJARNI_EN) {
			appTitleEl = <div className="app-title-container">
				<h1 class="app-title">
					<Link to="/DMII/">
						<span className="sub">The Database of Icelandic Morphology </span>
						<span>DIM</span>
					</Link>
				</h1>
				<small>Editor <a href="mailto:Kristín Ingibjörg Hlynsdóttir<kristin.ingibjorg.hlynsdottir@arnastofnun.is>"> Kristín Ingibjörg Hlynsdóttir</a></small>
				<small>Editor <a href="mailto:Kristín Bjarnadóttir<kristinb@hi.is>">Kristín Bjarnadóttir</a></small>
				<h2>DMII-Core</h2>
			</div>
		}
		else if (site == sites.EN) {
			appTitleEl = <div className="app-title-container">
				<h1 className="app-title">
					<Link to="/">
						<span className="sub">The Database of Icelandic Morphology </span>
						<span>DIM</span>
					</Link>
				</h1>
				<small>Editor <a href="mailto:Kristín Ingibjörg Hlynsdóttir<kristin.ingibjorg.hlynsdottir@arnastofnun.is>"> Kristín Ingibjörg Hlynsdóttir</a></small>
				<small>Editor <a href="mailto:Kristín Bjarnadóttir<kristinb@hi.is>">Kristín Bjarnadóttir</a></small>
			</div>;
		}
		else {
			appTitleEl = <div className="app-title-container">
				<h1 className="app-title">
					<Link to="/">
						<span className="sub">Beygingarlýsing íslensks nútímamáls </span>
						<span>BÍN</span>
					</Link>
				</h1>
				<small>Ritstjóri <a href="mailto:Kristín Ingibjörg Hlynsdóttir<kristin.ingibjorg.hlynsdottir@arnastofnun.is>"> Kristín Ingibjörg Hlynsdóttir</a></small>
				<small>Ritstjóri <a href="mailto:Kristín Bjarnadóttir<kristinb@hi.is>">Kristín Bjarnadóttir</a></small>
			</div>;
		}

		return (
			<BrowserRouter ref="router" basename="/">

				<div className={'app-container container-fluid'+
					' site-'+siteInitials+
					' site-lang-'+langInitials+
					//(isOnContentPage ? ' searchform-minimized' : '')+
					(this.state.loading ? ' loading' : '')
				}>

					<div className="row d-flex no-gutters">

						<button className="app-menu-button btn-menu" ref="menuButton" onClick={this.menuButtonClickHandler}>
							<div className={'hamburger-icon'+(this.state.menuOpen ? ' open' : ' black')}>
								<div className="icon-middle" />
								<div className="icon-before-after" />
							</div>
						</button>

						<div className={'col-12 col-md-5 col-lg-4 col-xl-3 app-menu'+(this.state.menuOpen ? ' open' : '')}>

							<div className="menu-content" ref="menuContent">

								<button className="menu-close-button btn btn-link" onClick={
									function() {
										this.setState({
											menuOpen: false
										});
									}.bind(this)}>
									<div className="hamburger-icon open">
										<div className="icon-middle" />
										<div className="icon-before-after" />
									</div>
								</button>

								<header>

							        <a className="site-logo" title="Stofnun Árna Magnússonar í íslenskum fræðum" href="http://arnastofnun.is"><img src={logo} className="logo" /></a>

									{appTitleEl}

								</header>

								<div className="page-links">
									<PageMenu site={site} />
								</div>

							</div>

						</div>

						<div className="col-12 col-md-7 col-lg-8 col-xl-9 main-column">
							<div className="row">

								<Route path="/">
									<Switch>

										<Route exact path="/" render={(routeProps) => (
											<React.Fragment>
												<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd} 
													searchString={this.state.searchParams.searchString} />
											</React.Fragment>
										)} />

										<Route exact path="/forsida/" render={(routeProps) => (
											<React.Fragment>
												<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd} 
													searchString={this.state.searchParams.searchString} />
											</React.Fragment>
										)} />

										<Route exact path="/DMII/" render={(routeProps) => (
											<React.Fragment>
												<SearchBox lang="en" beygingarmynd={this.state.searchParams.beygingarmynd} 
													searchString={this.state.searchParams.searchString} />
											</React.Fragment>
										)} />

										<Route exact path="/leit/beygingarmynd/:searchString" render={(routeProps) => (
											<React.Fragment>
												<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd} 
													searchString={this.state.searchParams.searchString} />
											</React.Fragment>
										)} />

										<Route exact path="/leit/:searchString" render={(routeProps) => (
											<React.Fragment>
												<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd} 
													searchString={this.state.searchParams.searchString} />
											</React.Fragment>
										)} />

										<Route exact path="/beyging/:entryId" render={(routeProps) => (
											<React.Fragment>
												<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd} 
													searchString={this.state.searchParams.searchString} />
											</React.Fragment>
										)} />
									</Switch>
								</Route>

								<div className="col-12 col-md-12 col-lg-12 col-xl-12">

									<div className="column-content">

										<Route exact path="/leit" render={function(routeProps) {
											let queryObj = _.object(_.compact(_.map(routeProps.location.search.slice(1).split('&'), function(item) {  if (item) return item.split('='); }))); 
											console.log(queryObj)
											if (queryObj.q) {
												return <Redirect to={'/leit/'+queryObj.q} />
											}
											if (queryObj.id) {
												return <Redirect to={'/beyging/'+queryObj.id} />
											}
										}} />

										<Route exact path="/leit/beygingarmynd/:searchString" render={(routeProps) => (
											<React.Fragment>
												<SearchResultsList beygingarmynd={true} onSearch={this.searchResultsListSearchHandler} onLoadStart={this.loadStartHandler} onLoadFinish={this.loadFinishHandler} {...routeProps} />
											</React.Fragment>
										)} />

										<Route exact path="/leit/:searchString" render={(routeProps) => (
											<React.Fragment>
												<SearchResultsList onSearch={this.searchResultsListSearchHandler} onLoadStart={this.loadStartHandler} onLoadFinish={this.loadFinishHandler} {...routeProps} />
											</React.Fragment>
										)} />

										<Route exact path="/beyging/:entryId" render={(routeProps) => (
											<React.Fragment>
												<EntryView onLoadStart={this.loadStartHandler} onLoadFinish={this.loadFinishHandler} {...routeProps} />
											</React.Fragment>
										)} />

										<Route exact path="/*" render={(routeProps) => (
											<React.Fragment>
												<PageView />
												{
													/*
													isOnContentPage &&
													<Link className="floating-search-button" to="/"></Link>
													*/
												}
											</React.Fragment>
										)} />

										<Route exact path="/kornhladan" render={(routeProps) => (
											<MolarList />
										)} />

										<Route exact path="/korn/:kornId" render={(routeProps) => (
											<MolarView {...routeProps} />
										)} />

										<Route exact path="/gogn/mimisbrunnur/" render={(routeProps) => (
											<DownloadList />
										)} />

										<Route exact path="/gogn/mimisbrunnur/:link_id" render={(routeProps) => (
											<div className="page-content">
												<div className="html-content">
													<h2>Gögn til niðurhals</h2>
													<DownloadList />
												</div>
											</div>
										)} />

										<Route exact path="/DMII/LTdata/data/" render={(routeProps) => (
											<DownloadPage lang="en" />
										)} />

										<Route exact path="/DMII/LTdata/data/:link_id" render={(routeProps) => (
											<div className="page-content">
												<div className="html-content">
													<h2>Download</h2>
													<DownloadList lang="en" />
												</div>
											</div>
										)} />

									</div>

								</div>
							</div>

							<SiteFooter />

						</div>

					</div>

					<div className="mobile-header">
						<h1>Beygingarlýsing íslensks nútímamáls</h1>
					</div>

					<GoogleAnalytics />

				</div>

			</BrowserRouter>
		);
	}
}

export default App;
