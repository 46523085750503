export default {
	siteTitle: 'Beygingarlýsing íslensks nútímamáls',
	pageSize: 50,

	//binPhpRoot: 'http://130.208.178.121/php_bin/ajaxleit2.php',
	binPhpRoot: process.env.REACT_APP_PHP_ROOT,

	//apiRoot: 'http://130.208.178.119:8800'
	apiRoot: process.env.REACT_APP_API_ROOT,

	gaTrackingId: 'G-Z3VPWWW3Z7'
}
